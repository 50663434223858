import { Box, Button, Modal, ModalCloseButton, ModalContent, ModalOverlay, SimpleGrid, Stack, Text, Theme, useDisclosure, useTheme } from '@chakra-ui/react';
import CardItem from 'components/CardItem/CardItem';
import DashboardSettings from 'components/DashboardSettings/DashboardSettings';
import { isEmpty } from 'lodash';
import { useHomeStore } from 'pages/Home/store/useHomeStore';
import { memo, useEffect, useState } from 'react';
import { Colors } from 'themes/colors';
import './styles.css';

interface Params {
  offset: number;
  limit: number;
}
interface ListCoinProps {
  filterWith?: string;
  onChangeFilter?: (params: Params) => void;
  isReset?: boolean;
}

function ListCoin({ onChangeFilter, isReset }: ListCoinProps) {
  const theme = useTheme<Theme>();
  const colors = theme.colors as Colors;

  const { isOpen, onClose } = useDisclosure();

  const { pairs, total } = useHomeStore();
  const [pageCount, setPageCount] = useState(0);

  const [filterObj, setFilterObj] = useState<Params>({
    offset: 0,
    limit: 24,
  });

  useEffect(() => {
    if (isReset) {
      setFilterObj({ offset: 0, limit: filterObj.limit });
    }
  }, [isReset]);

  useEffect(() => {
    onChangeFilter?.(filterObj);
  }, [filterObj]);

  useEffect(() => {
    if (!isEmpty(pairs)) {
      const totalPages = Math.ceil(total / filterObj.limit);
      setPageCount(totalPages);
    }
  }, [filterObj.limit, pairs]);

  const handleChangeItemsPerPage = (val: number) => {
    setFilterObj((prev) => ({
      ...prev,
      limit: val,
      offset: 0,
    }));
  };

  const handlePageClick = (type: string) => () => {
    if (type === 'next') {
      setFilterObj((prev) => ({
        ...prev,
        offset: prev.offset + filterObj.limit,
      }));
    } else {
      setFilterObj((prev) => ({
        ...prev,
        offset: prev.offset - filterObj.limit,
      }));
    }
  };

  const nextButton = (
    <Button
      disabled={Math.ceil(filterObj.offset / filterObj.limit) + 1 === pageCount}
      bgColor="#182033"
      onClick={handlePageClick('next')}
      _hover={{
        bgColor: colors.primary,
        color: '#fff',
      }}>
      <Text color="#fff">{` Next >`}</Text>
    </Button>
  );

  const prevButton = (
    <Button
      disabled={Math.ceil(filterObj.offset / filterObj.limit) + 1 === 1}
      bgColor="#182033"
      onClick={handlePageClick('prev')}
      _hover={{
        bgColor: colors.primary,
        color: '#fff',
      }}>
      <Text color="#fff">{`< Previous`}</Text>
    </Button>
  );

  const renderDashboardSettings = () => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered preserveScrollBarGap blockScrollOnMount={false}>
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent position="relative" maxW="40rem" bgColor={colors.bgPrimary} padding="24px">
          <DashboardSettings onChangeItemDisplay={handleChangeItemsPerPage} defaultValue={filterObj.limit} />
          <ModalCloseButton
            position="absolute"
            top={-5}
            right={-5}
            borderRadius="full"
            color="#333"
            bgColor="#94A3B8"
            _hover={{
              bgColor: '#94A3B8',
            }}
          />
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Box cursor={'pointer'}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="1rem">
        {pairs.map((item, idx) => (
          <CardItem
            key={idx.toString()}
            index={idx}
            price={item[item.defaultBase].nativePrice}
            change={item[item.defaultBase].nativePriceChange24h.toFixed(2)}
            coinName={item[item.defaultBase].name}
            mktCap={item[item.defaultBase].usdMcap}
            vol={item.usdVolume24h}
            icon={item[item.defaultBase].icon}
            symbol={item[item.defaultBase].symbol + '/' + item[item.defaultBase === 'asset0' ? 'asset1' : 'asset0'].symbol}
            pairAddress={item.pairAddress}
            symbolOrigin={item[item.defaultBase === 'asset0' ? 'asset1' : 'asset0'].symbol}
            chain={item.chain}
            dex={item.dex}
          />
        ))}
      </SimpleGrid>
      <Box my="20px">
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
          {prevButton}
          <Box bgColor="#182033" py="8px" px="12px" borderRadius={4} color="#fff">
            {Math.ceil(filterObj.offset / filterObj.limit) + 1}
          </Box>
          {nextButton}
        </Stack>
      </Box>
      {renderDashboardSettings()}
    </Box>
  );
}

export default memo(ListCoin);
