import { Box, Button, Center, Flex, Spinner, Stack, Theme, useDisclosure, useTheme } from '@chakra-ui/react';
import TraddingViewChart from 'components/Chart/TraddingView';
import Exchange from 'components/Exchange/Exchange';
import ModalSearch from 'components/ModalSearch/ModalSearch';
import { SVGIcon } from 'components/SVGIcon/SVGIcon';
import SwapInfo from 'components/SwapInfo/SwapInfo';
import TableTx from 'components/TableTx/TableTx';
import Volume from 'components/Volume/Volume';
import { useGetContextSwap } from 'hooks/useGetContextSwap';
import { useGetPairByAddress } from 'hooks/useGetPairByAddress';
import { useGetTx } from 'hooks/useGetTx';
import { isEmpty } from 'lodash';
import { Pairs, Tx } from 'models/Pairs';
import { useHomeStore } from 'pages/Home/store/useHomeStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Colors } from 'themes/colors';

const Swap = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const param = useParams();
  const theme = useTheme<Theme>();
  const colors = theme.colors as Colors;
  const { pairs: coins } = useHomeStore();
  const [txs, setTxs] = useState<Tx[]>([]);
  const [addresses, setAddresses] = useState<Pairs[]>([]);
  const [pairInfo, setPairInfo] = useState<any>();
  const [contextSwap, setContextSwap] = useState<any>();
  // const getListTransaction = useGetTx(param.pairAddress);
  // const getPairByAddress = useGetPairByAddress(param.chain);
  // const getContextSwap = useGetContextSwap(`/${param.chain?.toLowerCase()}/${param.pairAddress}`);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.code === 'KeyK' && event.metaKey) {
        onOpen();
      }
    });
  }, []);
  // useEffect(() => {
  //   if (getListTransaction.data && !isEmpty(getListTransaction.data)) {
  //     setTxs(getListTransaction.data.txs);
  //   }
  // }, [getListTransaction.data, param]);

  // useEffect(() => {
  //   if (getContextSwap.data && !isEmpty(getContextSwap.data)) {
  //     console.log('getContextSwap', getContextSwap.data);
  //     const pageContext = getContextSwap.data.pageContext;
  //     setContextSwap(pageContext);
  //     setPairInfo(pageContext.pageProps.pairInfo);
  //     console.log('pairInfo', pageContext.pageProps.pairInfo);
  //   }
  // }, [getPairByAddress.data, param]);

  const btn = {
    width: 'calc(100% / 3 - 4px)',
    background: `${colors.bg3}`,
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Nunito Sans',
    fontSize: '18px',
    color: colors.light2,
    fontWeight: 600,
  };
  const renderLoading = () => {
    return (
      <Center height="100%">
        <Spinner size="xl" />
      </Center>
    );
  };
  return (
    <Box maxW={'1440px'} margin={'0 auto'}>
      <Box
        maxW={'1024px'}
        mx="auto"
        display={'flex'}
        flexDirection={{
          md: 'row',
          sm: 'column',
        }}>
        <Box flex={1} display="flex" justifyContent={'space-between'} flexDirection="column" height={500}>
          <TraddingViewChart symbol="BTCUSD" />
        </Box>
      </Box>
    </Box>
  );
};

export default Swap;
