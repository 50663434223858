export const truncateAddress = (address) => {
  if (!address) return 'No Account';
  const match = address.match(/^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return '0x' + val.toString(16);
};

export const formatPriceApicompare = (value: string | number) => {
  return value?.toString()?.replace(/\s/g, '') || 0;
};

export const formatVolApiCompare = (value: string | number) => {
  const splitData = value?.toString().split('$')[1];
  if (!splitData) return value;
  const data = splitData?.split('.')[0] || splitData;
  if (data.length <= 3) return parseInt(data.replace(/,/g, ''));
  else if (data.length <= 7 && data.length > 3) return (parseInt(data.replace(/,/g, '')) / 1000).toFixed(1) + 'K';
  else if (data.length > 7) return (parseInt(data.replace(/,/g, '')) / 1000000).toFixed(1) + 'M';
};

export const borderColorMap = (name: string, dex?: string) => {
  const _name = name.replace(' ', '');
  const borders = {
    TerraClassic: '#268CDB',
    Osmosis: '#8B5CF6',
    Juno: '#F0827D',
    'Terra2.0': '#268CDB',
    Near: '#00C5A2',
    TerraFloki: '#FEE700',
  };

  if (dex == 'TerraFloki') return borders.TerraFloki;
  return borders[_name] ? borders[_name] : borders.TerraClassic;
};

export const getTitle = (name: string) => {
  const _name = name.replace(' ', '');
  const titles = {
    TerraClassic: '#FACC15',
    Osmosis: '#C084FC',
    Juno: '#F87171',
    'Terra2.0': '#FB923C',
    Near: '#fff',
  };

  return titles[_name] ? titles[_name] : titles.TerraClassic;
};

export const getTitleDex = (name: string) => {
  const _name = name.replace(' ', '');
  const titles = {
    Osmosis: {
      bg: '#E9D5FF',
      color: '#0F172A',
    },
    RefFinance: {
      bg: '#00C6A2',
      color: '#0F172A',
    },
    Astroport: {
      bg: '#268CDB',
      color: '#DBEAFE',
    },
    JunoSwap: {
      bg: '#000',
      color: '#FBCFE8',
    },
    Terraswap: {
      bg: '#0322BB',
      color: '#DBEAFE',
    },
    Loop: {
      bg: '#BF21AA',
      color: '#FCE7F3',
    },
  };

  return titles[_name] ? titles[_name] : titles.Osmosis;
};

export const getDex = (name: string) => {
  const _name = name.replace(' ', '');
  const dexs = {
    Osmosis: 'https://coinhall.org/assets/chain_logos/osmo.svg',
    RefFinance: 'https://coinhall.org/dex/ref.png',
    Astroport: 'https://coinhall.org/dex/astroport.svg',
    JunoSwap: 'https://coinhall.org/dex/junoswap.svg',
    Terraswap: 'https://coinhall.org/dex/terraswap.svg',
    Loop: 'https://coinhall.org/dex/loop.png',
    TerraFloki: 'https://terrafloki.io/tfloki_dex_logo.svg',
  };

  return dexs[_name] ? dexs[_name] : dexs.Osmosis;
};

export const parseRoute = (chain: string) => {
  if (chain == 'Terra 2.0') {
    return 'terra';
  }

  console.log('chain', chain.replace(' ', '-').toLowerCase());
  return chain.replace(' ', '-').toString().toLowerCase();
};

export const formatPrice = (price: number | string) => {
  const format = price.toString().replaceAll('.', '').slice(0, 5);
  console.log('format', format);
  return format;
};

export const trunceHash = (hash: string) => {
  const prefix = hash.slice(0, 5);
  const suffix = hash.slice(-5);
  return prefix + '...' + suffix;
};
