import { ethers } from 'ethers';
import Layout from 'layout/Layout';
import Home from 'pages/Home/Home';
import Login from 'pages/Login/Login';
import Pairs from 'pages/Pairs/Pairs';
import Subscribe from 'pages/Subscribe/Subscribe';
import Swap from 'pages/Swap/Swap';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes } from 'react-router-dom';
import { PersistGate } from 'zustand-persist';

const queryClient = new QueryClient();
(window as any).ethers = ethers;
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <PersistGate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/pairs" element={<Pairs />} />
            <Route path="/:chain/:pairAddress" element={<Swap />} />
            <Route
              path="*"
              element={
                <main style={{ padding: '1rem' }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </PersistGate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
