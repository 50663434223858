import { Box, Center, Container, Flex, Spinner } from '@chakra-ui/react';
import FilterList from 'components/FilterList/FilterList';
import { useGetPairs } from 'hooks/useGetParis';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useHomeStore } from './store/useHomeStore';

export default function Home() {
  const { pairs, setPairs } = useHomeStore();
  const [filter, setFilter] = useState({});

  const getListPairsQuery = useGetPairs(filter);

  useEffect(() => {
    if (getListPairsQuery.data && !isEmpty(getListPairsQuery.data)) {
      setPairs(getListPairsQuery.data);
    }
  }, [getListPairsQuery.data]);

  if (isEmpty(pairs)) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }
  return (
    <Box my={4}>
      <Container maxW="5xl">
        <Flex maxW="42rem" width="100%" mx="auto">
        </Flex>
        <FilterList onChangeValue={setFilter} />
      </Container>
    </Box>
  );
}
