import { Box, useDisclosure } from '@chakra-ui/react';
import Banner from 'components/Banner/Banner';
import BlockCount from 'components/BlockCount/BlockCount';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import ModalWallet from 'components/ModalWallet/ModalWallet';
import Trending from 'components/Trending/Trending';
import useWallet from 'hooks/useWallet';
import { Outlet } from 'react-router-dom';

export default function Layout() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { connectWallet, account, disconnect } = useWallet();

  return (
    <Box minH="100vh" position="relative" overflowX="hidden">
      <Header onConnectedWallet={onOpen} account={account} onDisconnect={disconnect} />
      <Banner />
      <Trending />
      <Box minH="100vh" pb={{ base: '24px', md: 0 }}>
        <Outlet />
      </Box>
      <BlockCount />
      <Footer />
      {/* {!!account && } */}

      <ModalWallet isOpen={isOpen} onClose={onClose} children={undefined} onConnectWallet={connectWallet} />
    </Box>
  );
}
